/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    balloon: {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" fill-rule="evenodd" d="M8 9.984C10.403 9.506 12 7.48 12 5a4 4 0 00-8 0c0 2.48 1.597 4.506 4 4.984M13 5c0 2.837-1.789 5.227-4.52 5.901l.244.487a.25.25 0 11-.448.224l-.008-.017c.008.11.02.202.037.29.054.27.161.488.419 1.003.288.578.235 1.15.076 1.629-.157.469-.422.867-.588 1.115l-.004.007a.25.25 0 11-.416-.278c.168-.252.4-.6.533-1.003.133-.396.163-.824-.049-1.246l-.013-.028c-.24-.48-.38-.758-.448-1.102a3 3 0 01-.052-.45l-.04.08a.25.25 0 11-.447-.224l.244-.487C4.789 10.227 3 7.837 3 5a5 5 0 0110 0m-6.938-.495a2 2 0 011.443-1.443C7.773 2.994 8 2.776 8 2.5s-.226-.504-.498-.459a3 3 0 00-2.46 2.461c-.046.272.182.498.458.498s.494-.227.562-.495"/>',
    },
});
